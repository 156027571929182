<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseEvaluator-${evaluator.id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <i
          data-feather="circle"
          :class="{
            'text-warning': validated > 0 && validated < evaluator.applicants.length,
            'text-danger': validated === 0,
            'text-success': validated === evaluator.applicants.length,
          }"
        /> <a data-action="collapse">{{ evaluator.name }} - {{ evaluator.area ? evaluator.area.code : '-' }}</a>
        <span
          class="badge badge-mini ms-1"
          :class="{
            'bg-light-warning': validated > 0 && validated < evaluator.applicants.length,
            'bg-light-danger': validated === 0,
            'bg-light-success': validated === evaluator.applicants.length,
          }"
        >R{{ round > 4 && round < 8 ? round - 5 : '' }} - {{ validated }}/{{ evaluator.applicants.length }}</span>
        <br>
        <small class="ps-2">Last access: {{ evaluator.last_access }}</small>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li><a
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View profile"
          ><i data-feather="user" /></a></li>
          <li><a data-action="collapse"><i data-feather="chevron-down" /></a></li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseEvaluator-${evaluator.id}`"
    >
      <div class="card-content">
        <div class="card-body">
          <div class="table-sm-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th v-if="round == 5">
                    Evaluated R0
                  </th>
                  <th v-if="round == 6">
                    Evaluated R1
                  </th>
                  <th v-if="round == 6">
                    AVG Grade
                  </th>
                  <th v-if="round == 7">
                    Evaluated R2
                  </th>
                  <th>Data evaluation</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="applicant in evaluator.applicants"
                  :key="applicant.id"
                >
                  <td @click="$emit('selectItem', applicant), $store.dispatch('modals/toggleFormEvaluation', true)">
                    {{ applicant.last_name }}, {{ applicant.first_name }}
                  </td>
                  <td v-if="round == 5">
                    <span
                      class="badge"
                      :class="{'badge-light-success': applicant.pivot.interest_conflict !== true && applicant.pivot.competent === true, 'badge-light-danger': applicant.pivot.interest_conflict !== true && applicant.pivot.competent === false, 'badge-light-warning': applicant.pivot.interest_conflict !== true && applicant.pivot.competent === null, 'badge-light-info': applicant.pivot.interest_conflict === true }"
                    >
                      <template v-if="applicant.pivot.interest_conflict === true">Conflict of interest</template>
                      <template v-else-if="applicant.pivot.competent === true">Competitive</template>
                      <template v-else-if="applicant.pivot.competent === false">Non-competitive </template>
                      <template v-else-if="applicant.pivot.competent === null">Not evaluated</template>
                    </span>
                  </td>
                  <td v-if="round == 6">
                    <span
                      class="badge"
                      :class="{'badge-light-success': applicant.pivot.avg_grade, 'badge-light-danger': !applicant.pivot.avg_grade, }"
                    >
                      {{ applicant.pivot.avg_grade ? 'Yes' : 'No' }}
                    </span>
                  </td>
                  <td v-if="round == 6">
                    {{ applicant.pivot.avg_grade }}
                  </td>
                  <td v-if="round == 7">
                    <span
                      class="badge"
                      :class="{'badge-light-success': applicant.pivot.confirm, 'badge-light-danger': !applicant.pivot.confirm, }"
                    >
                      {{ applicant.pivot.confirm ? 'Yes' : 'No' }}
                    </span>
                  </td>
                  <td>{{ applicant.pivot.grading_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    evaluator: { type: Object, required: true, default: () => null },
    round: { type: [Number, String], required: false, default: () => null },
  },
  data() {
    return {
      selectedItem: {},
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      filters: 'filters/filters',
    }),
    validated() {
      if (this.round === 5) {
        return this.evaluator.applicants.filter(e => e.pivot.competent != null || e.pivot.interest_conflict === true).length
      }

      if (this.round === 6) {
        return this.evaluator.applicants.filter(e => e.pivot.avg_grade !== null).length
      }

      if (this.round === 7) {
        return this.evaluator.applicants.filter(e => e.pivot.confirm !== false).length
      }

      return 0
    },
  },
  async mounted() {
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 150)
  },
  methods: {

  },
}
</script>
